@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gelasio:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

body {
  position: relative;
  min-height: 100vh;
}

:root {
  --primary: #2d2f31;
  --secondary: #5624d0;
  --light-gray: #f7f9fa;
  --tabs-default: #6a6f73;
  --purple: #a435f0;
  --purple-hover: #8710d8;
  --green : #1e6055;
  --dark-gray: #1c1d1f;
  --light-purple: #c0c4fc;
  --gold: #f69c08;
  --dark-purple-hover: #401b9c;
}


.navbar #navbar-desktop {
  height: 4.5rem;
}


.search button:disabled svg {
  color: #929496;
}

.search button svg {
  color:  var(--primary);
}

.navbar > #header-desktop > div:not(.search):hover {
  color: var(--secondary);
}

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}

.embla__slide {
  min-width: 0;
}

.hero-carousel .embla__slide {
  flex: 0 0 100%;
}

/* .courses-carousel .embla__slide {
  flex: 0 0 20%;
} */

.hero-carousel .carousel-button {
  top: 50%;
  transform: translateY(-50%);
}

.hero-carousel .carousel-button.left {
  left: .8rem;
}

.hero-carousel .carousel-button.right {
  right: .8rem;
}

.carousel-button svg {
  color: white;
  font-size: 2rem;
}

.stars > span {
  font-size: 0.9rem;
  color: #b4690e;
}

.course-card-content h3 a::before, .course-featured-content h3 a::before, .cart-item a::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.course-card:hover .course-thumbnail > div{ 
  opacity: 0.15;
}

.courses-carousel .carousel-button {
  top: 3rem;
  z-index: 10;
}

.courses-carousel .carousel-button.left {
  left: -1rem;
}

.courses-carousel .carousel-button.right {
  right: -1rem;
}

.carousel-wrapper {
  min-height: 500px;
}

@keyframes popup {
  0% {
    opacity: 0%;
    scale: 0.8;
  }
  100% {
    opacity: 100%;
    scale: 1;
  }
}

.popup-course, .course-list-popup, .sidebar-popup {
  animation: popup 0.15s ease-in;
  transform-origin: center;
}

.li-w-check .MuiSvgIcon-root {
  color: #2d2f31;
}

.popup-course.left {
  padding-left: 1rem;
}

.popup-course.right {
  padding-right: 1rem;
}

.ratings-filter input {
  background-color: black;
}

.ratings-filter.active .ratings-radio {
  display: block;
}
.ratings-filter button svg {
  transition: all .25s;
}

.ratings-filter.active button svg {
  transform: rotate(178deg)
}


@media screen and (min-width: 1024px) {
  .courses-section .filter-course-list .filter {
    width: 20%;
    transition: all 0.2s ease-in-out;
  }
  
  .courses-section .filter-course-list .courses-list {
    width: 80%;
    transition: all 0.2s ease-in-out;
    justify-self: flex-end;
  }
  
  .courses-section .filter-course-list.filter-disabled .filter {
    width: 0%;
    visibility: hidden;
    padding: 0;
    opacity: 0;
  }
  
  .courses-section .filter-course-list.filter-disabled .courses-list {
    width: 100%;
  }
}


@media screen and (min-width: 300px) {
  .course-page .container {
    max-width: 600px;
  }
}

@media screen and (min-width: 1024px) {
  .course-page .container {
    max-width: 1184px;
  }
}



.course-page .course-page-hero .rating-details .rating {
  color: var(--gold);
}

.course-page .course-page-hero .rating-details .stars > span, .course-page-hero .rating-details .stars .MuiRating-iconEmpty {
  font-size: 0.9rem;
  color: var(--gold);
}

.course-page .course-page-hero .update-language svg {
  font-size: 1rem;
}

.course-content-section-item svg {
  font-size: 1rem;
}

.course-content .section-list-item button svg {
  transition: all .3s ease-in-out;
}

.course-content .section-list-item.collapsed .content-items {
  display: none;
}

.course-content .section-list-item.collapsed button svg {
  transform: rotate(178deg);
}

.what-you-will-learn .list-items.collapsed {
  -webkit-mask-image: linear-gradient(to bottom, #ffffff 20%, transparent 100%);
}

.loader {
  width: 1.2rem;
  height: 1.2rem;
  border: 3px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
} 

.off-canvas-overlay {
  background: rgba(0,0,0, 0.6);
}

@keyframes left {
  0% {
    transform: translateX(-16rem);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes left-closing {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-16rem);
  }
}

@keyframes right {
  0% {
    transform: translateX(16rem);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes right-closing {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(16rem);
  }
}

@keyframes opacity-closing {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.off-canvas {
  left: 0;
  animation: opacity 0.2s;
  display: none;
}

[data-position="left"].off-canvas .off-canvas-content-wrapper {
  animation: left 0.3s;
}

[data-position="left"].off-canvas.closing .off-canvas-content-wrapper {
  transform: translateX(0);
  animation: left-closing 0.3s forwards;
}

[data-position="left"].off-canvas > .close-btn {
  left: 16rem;
  margin-left: 1rem;
}
[data-position="left"].off-canvas .off-canvas-content-wrapper {
  left: 0;
}

[data-position="right"].off-canvas .close-btn {
  right: 16rem;
  margin-right: 1rem;
}

[data-position="right"].off-canvas .off-canvas-content-wrapper {
  animation: right 0.3s;
}

[data-position="right"].off-canvas.closing .off-canvas-content-wrapper {
  transform: translateX(0);
  animation: right-closing 0.3s forwards;
}

[data-position="right"].off-canvas .off-canvas-content-wrapper {
  right: 0;
}

.off-canvas.closing {
  opacity: 1;
  animation: opacity-closing 0.2s forwards;
}

.off-canvas .close-btn {
  animation: popup 0.2s 0.5s forwards;
}


@media screen and (max-width: 1042px) {
  .tabs .left-btn {
    background: linear-gradient(to right, #ffffff 65%, transparent 100%)
  }
  .tabs .right-btn {
    background: linear-gradient(to left, #ffffff 65%, transparent 100%)  
  }
}

@media screen and (max-width: 767px) {
  .categories-list-wrapper a {
    border: 1px solid var(--primary);
  }
}

@keyframes to-bottom-height {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 500px;
    opacity: 1;
  }
}

.search-bar-result .search-bar-result-wrapper {
  animation: to-bottom-height 0.15s ease-in;
}